import styled, {keyframes} from "styled-components";
import n1 from "../assets/carousel-images/n1.png"
import n2 from "../assets/carousel-images/n2.png"
import n3 from "../assets/carousel-images/n3.png"
import n4 from "../assets/carousel-images/n4.png"
import n5 from "../assets/carousel-images/n5.png"
import n6 from "../assets/carousel-images/n6.png"
import n7 from "../assets/carousel-images/n7.png"
import n8 from "../assets/carousel-images/n8.png"
import n9 from "../assets/carousel-images/n9.png"
import n10 from "../assets/carousel-images/n10.png"
import n11 from "../assets/carousel-images/n11.png"
import n12 from "../assets/carousel-images/n12.png"
import n13 from "../assets/carousel-images/n13.png"
import n14 from "../assets/carousel-images/n14.png"

const Carousel = () => {
  return (
    <CarouselContainer>
      <CarouselItems>
        <CarouselItem> 
            <img alt="" src={n1}/>
        </CarouselItem>
        <CarouselItem> 
            <img alt="" src={n2}/>
        </CarouselItem>
        <CarouselItem> 
            <img alt="" src={n3}/>
        </CarouselItem>
        <CarouselItem> 
            <img alt="" src={n4}/>
        </CarouselItem>
        <CarouselItem> 
            <img alt="" src={n5}/>
        </CarouselItem>
        <CarouselItem> 
            <img alt="" src={n6}/>
        </CarouselItem>
        <CarouselItem> 
            <img alt="" src={n7}/>
        </CarouselItem>
        <CarouselItem> 
            <img alt="" src={n8}/>
        </CarouselItem>
        <CarouselItem> 
            <img alt="" src={n9}/>
        </CarouselItem>
        <CarouselItem> 
            <img alt="" src={n10}/>
        </CarouselItem>
        <CarouselItem> 
            <img alt="" src={n11}/>
        </CarouselItem>
        <CarouselItem> 
            <img alt="" src={n12}/>
        </CarouselItem>
        <CarouselItem> 
            <img alt="" src={n13}/>
        </CarouselItem>
        <CarouselItem> 
            <img alt="" src={n14}/>
        </CarouselItem>
       
        <CarouselItem> 
            <img alt="" src={n1}/>
        </CarouselItem>
        <CarouselItem> 
            <img alt="" src={n2}/>
        </CarouselItem>
        <CarouselItem> 
            <img alt="" src={n3}/>
        </CarouselItem>
        <CarouselItem> 
            <img alt="" src={n4}/>
        </CarouselItem>
        <CarouselItem> 
            <img alt="" src={n5}/>
        </CarouselItem>
        <CarouselItem> 
            <img alt="" src={n6}/>
        </CarouselItem>
        <CarouselItem> 
            <img alt="" src={n7}/>
        </CarouselItem>
        <CarouselItem> 
            <img alt="" src={n8}/>
        </CarouselItem>
        <CarouselItem> 
            <img alt="" src={n9}/>
        </CarouselItem>
        <CarouselItem> 
            <img alt="" src={n10}/>
        </CarouselItem>
        <CarouselItem> 
            <img alt="" src={n11}/>
        </CarouselItem>
        <CarouselItem> 
            <img alt="" src={n12}/>
        </CarouselItem>
        <CarouselItem> 
            <img alt="" src={n13}/>
        </CarouselItem>
        <CarouselItem> 
            <img alt="" src={n14}/>
        </CarouselItem>
       
      </CarouselItems>
    </CarouselContainer>
  );
};
//background-color: #23da79;73EC43;A3B0B4;D6DFE4;
export default Carousel;
const scroll = keyframes`
0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 14));
  }
  `;
const CarouselContainer = styled.div`
padding: 2rem 0;
width: 100%;
height: auto;
overflow: hidden;
position: relative;
display: grid;
place-items: center;
top: 2rem;`;
const CarouselItems = styled.div`  
display: flex;
width: calc(250px * 28);
animation: ${scroll} 24s linear infinite;
`;
const CarouselItem = styled.div`  
height: 200px;
width: 250px;
display: flex;
align-items: center;
margin: 0 30px;
img{
    width: 100%;
    border-radius: 20px;
}
`;
