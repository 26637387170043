import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./components/Home";
import GlobalFonts from "./fonts/_fonts";
import LoadingX from "./components/ui/LoadingX";

import './Loading.css';

function App() {
  return (
    <>
    <LoadingX/>
  <GlobalFonts/>
  <Router>
    <Routes>
      <Route path="/" element={<Home/>}/>
    </Routes>
  </Router>
  </>
  );  
}



export default App;
