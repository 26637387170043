import { createGlobalStyle } from "styled-components";
import NasalizationWoff from "./Nasalization-Regular.woff"
import NasalizationWoff2 from "./Nasalization-Regular.woff2"
import EthnocentricWoff from "./Ethnocentric-Regular.woff"
import EthnocentricWoff2 from "./Ethnocentric-Regular.woff2"
export default createGlobalStyle`
@font-face{
    font-family: 'Nasalization';
    src: local('Nasalization'), local('Nasalization'),
    url(${NasalizationWoff2}) format('woff2'),
    url(${NasalizationWoff}) format('woff');
    font-weight: 500;
    font-style: normal;
}
@font-face{
    font-family: 'Ethnocentric';
    src: local('Ethnocentric'), local('Ethnocentric'),
    url(${EthnocentricWoff2}) format('woff2'),
    url(${EthnocentricWoff}) format('woff');
    font-weight: 500;
    font-style: normal;
}
`