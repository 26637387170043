import styled from "styled-components";
import { device } from "../../device";
const PrimaryBtn = ({ text, style }) => {
  return (
    <Container style={style && style}>
      <p>{text}</p>
    </Container>
  );
};

export default PrimaryBtn;

const Container = styled.div.attrs((props) => ({
  fontSize: props.style && props.style.fontSize ? props.style.fontSize : "24px",
}))`
width:max-content;
padding:0.5rem 2rem;
border: 2px solid #76F016;
border-radius 24px;
cursor:pointer;
transition:all 300ms;
p{
    margin:0 !important;;
}
:hover{
  color: black;
  background-color: #d8ffea;
  box-shadow: 0px 0px 30px 0px #76F016;
}
@media ${device.tabletS} {
  font-size: 18px !important;
} 
`;
