import styled from "styled-components";
import { device } from "../../device";
import ImagenTeam from "../../assets/ImagenTeam.png";

const ArtistCard = ({ img, name, text, twitter }) => {
  return (
    <Container>
      <ImgContainer>
      <TeamImagen>
            <img class="TeamImagen" src={ImagenTeam} alt=""/>
          </TeamImagen>
          
          </ImgContainer>
      <h2>{name}</h2>
      <p>{text}</p>
      <Btn>
        <a  href="https://twitter.com/kryptopyansclub" 
        target="_blank" rel="noreferrer">
          {`@${twitter}`}</a>
      </Btn>
    </Container>
  );
};

export default ArtistCard;

const Container = styled.div`
  width: 230px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  p {
    font-size: 16px !important;
    margin-top: 0;
  }
  @media ${device.tabletMS} {
    margin:1rem 0;
  }
`;
const ImgContainer = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 100%;
  @media ${device.tabletMS} {
    width: 175px;
    height: 175px;
  }
`;
const Btn = styled.div`
  display: flex;
  color: white;
  padding: 0.5rem 2rem;
  border-radius: 12px;
  a {
    font-size: 20px !important;
    margin: 0;
    color: #73EC43;
  }
`;

const TeamImagen = styled.div`
`;