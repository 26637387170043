import React, { useEffect, useState } from "react";
import '../../Loading.css';
            
import Loading from "./Loading.jsx";

const LoadingX = () => {
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(true)
    }, 5000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
    { !isLoading ?
      <Loading/> : null
    }
    
    </>
  );
}

export default LoadingX;

