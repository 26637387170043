
import styled, { keyframes } from "styled-components";

import { useEffect, useState } from "react";
import { device } from "../device";
import WOW from "wowjs";

import PrimaryBtn from "./ui/PrimaryBtn";
import SecondaryBtn from "./ui/SecondaryBtn";

import Navbar from "./Navbar";
import BackgroundMatrix from "../assets/matrix.gif";
import BackgroundMatrixMobile from "../assets/matrixmobile.png";
import ImagenPortada from "../assets/ImagenPortada.png";
import Background10 from "../assets/Background/10.jpg";
import Carousel from "./Carousel";
import ImagenLeft from "../assets/ImagenLeft.png";
import ImagenLeft2 from "../assets/ImagenLeft2.png";
import ImagenLeft3 from "../assets/ImagenLeft3.gif";
import Background12 from "../assets/Background/12.jpg";
import ArtistCard from "./ui/ArtistCard";
import Roadmap from "./Roadmap";
import Footer from "./Footer";

// Secciones:

// Navbar
// Header
// Banner
// StorySection
// VideoSection
// AboutSection
// CarouselSection
// ClubSection
// TeamSection
// Roadmap
// FaqSection
// SmartContractSection
// Footer
// Color #73EC43


const Home = () => {
  const [viewAnswer1, setViewAnswer1] = useState(false);
  const [viewAnswer2, setViewAnswer2] = useState(false);
  const [viewAnswer3, setViewAnswer3] = useState(false);
  const [viewAnswer4, setViewAnswer4] = useState(false);
  const [viewAnswer5, setViewAnswer5] = useState(false);
  const [viewAnswer6, setViewAnswer6] = useState(false);
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);
  return (
    <Main>

      <Navbar />

      {/* Header_Inicio */}
      <Header className="showcase"> 
      
      <MatrixBackground>
        <img src={BackgroundMatrix} alt="" className="matrix"/>
      </MatrixBackground>
      
       <MatrixBackgroundMobile>
        <img src={BackgroundMatrixMobile} alt="" className="matrix"/>
      </MatrixBackgroundMobile>
     
      
      <SubHeader className="subHeader">
  
        <LeftLayout>
          <h1>
            Welcome 
            <br />
            to the
            <br />
            Kryptopyan
          </h1>
          <p>
            {" "}
            Access the metaverse through Kryptopyans.<br/>
            A Collection of 10,000 NFTs with 3D designs, one of a kind, hosted on the Ethereum network.
          </p>
          <a
              href="http://minting.kryptopyanworld.com/"
              target="_blank"
              rel="noreferrer"
            >
              <PrimaryBtn
            text="OPEN MINT"
            style={{
              fontSize: "26px",
              padding: "0.8em 3em",
              borderRadius: "100px",
            }}
          />
            </a>
          
        </LeftLayout>
        
        <PortadaContainer>
          <PortadaImagen>
            <img src={ImagenPortada} alt=""/>
          </PortadaImagen>
        </PortadaContainer>

        <PortadaContainerResponsive>
          <PortadaImagen>
          <img src={ImagenPortada} alt=""/>
          </PortadaImagen>
        </PortadaContainerResponsive>

        </SubHeader>

      </Header>
      {/* Header_Fin */}

      {/* Banner_Inicio */}
      <Banner>
        <Items>
          <Item>
            <h1> MINT NOVEMBER 30 </h1>
          </Item>
          <Item>
            <h1>  KRYPTOPYANS NFTs</h1>
          </Item>
          <Item>
            <h1> MINT NOVEMBER 30</h1>
          </Item>
          <Item>
            <h1> KRYPTOPYANS NFTs</h1>
          </Item>
        </Items>
      </Banner>
      {/* Banner_Fin */}

      {/* ClubSection2_Inicio -- id="about"> */}
      <ClubSection2>
        
        
        <LeftImagen>
            <img src={ImagenLeft2} alt=""/>
          </LeftImagen>
        <LayoutRight>
      
        
        <h1>
          About:
        <br></br><span>Interplanetary Age:</span>
        </h1>
        <p>
          The Kryptopians are beings from an exoplanet similar to Earth, 
          which orbits the star Alpha Centauri B of the triple star system Alpha Centauri
          <br />
          <br />
          Millions of years ago, the Kryptopyans visited Earth, 
          to give rise to human beings through their own DNA.
          <br /> <br />
          Today there is a civilization of innovative scientists, financiers, lovers of art and nature, 
          They have decided to settle their civilization on planet Earth to make themselves known.
          <br />  <br />
          Thanks to its alien technology and great scientific advances 
          they are ready and they will do it through the meteverse.
          <br />   <br />
          Very soon 10,000 inhabitants will arrive on the planet. 
          and they will do it in 2 visits, 
          first about 3000 will come, corresponding to the 1st mission.
          <br /> <br />
          And then the 7000 more will arrive, corresponding to the 2nd Mission.
          <br />
          <br />
        </p>

        </LayoutRight>
      </ClubSection2>
      {/* ClubSection2_Fin */}




      {/*
      <StorySection id="about">
        <h1>
          About:
        <br></br><span>Interplanetary Age:</span>
        </h1>
        <p>
          The Kryptopians are beings from an exoplanet similar to Earth, 
          which orbits the star Alpha Centauri B of the triple star system Alpha Centauri
          <br />
          <br />
          Millions of years ago, the Kryptopyans visited Earth, 
          to give rise to human beings through their own DNA.
          <br /> <br />
          Today there is a civilization of innovative scientists, financiers, lovers of art and nature, 
          They have decided to settle their civilization on planet Earth to make themselves known.
          <br />  <br />
          Thanks to its alien technology and great scientific advances 
          they are ready and they will do it through the meteverse.
          <br />   <br />
          Very soon 10,000 inhabitants will arrive on the planet. 
          and they will do it in 2 visits, 
          first about 3000 will come, corresponding to the 1st mission.
          <br /> <br />
          And then the 7000 more will arrive, corresponding to the 2nd Mission.
          <br />
          <br />
        </p>
      </StorySection>
      */}
      

      {/*
      <VideoSection>
        <h1>VIDEO</h1>
      </VideoSection>
      */}
      <Separador style={{ backgroundColor:'#76F016', padding:'1px' }}></Separador>
      
      {/* ClubSection3_Inicio -- id="about"> */}
      <ClubSection3>
      


        <LayoutRight3>
        <h1>
          Are you Ready for
          the <span>Kryptopyan</span>?
        </h1>
        <p>
          Kryptopyans is a collection of 10,000 NFTs (non-fungible tokens) based on the Ethereum network,
          which are made up of more than 1000 visual characteristics, of different rarities.
          <br />
          <br />
          Avatars Kryptopyans  are high quality 3D designs, including different Roles,
          like CyberPunks, Astronauts, Warriors, Soccer Players, Cybors and many more.
          <br /> <br />
          Among its Attributes you can find hundreds of Skins, Facial Expressions,
          incredible Clothes, Hats, Lenses, different Worlds and a lot of technology.
          <br />
          The Kryptopyans Metaverse is already under construction
          and experiences will come that will only be available to collectors.
          <br />
          <br />
          Owning a Kryptopyan allows you to participate in Airdrops,
          access exclusive products in the store, attend virtual events,
          and be part of a great community.
          <br />
          <br />
          To know more you can see our <a class="colorRoadmap" href="#roadmap">Roadmap.</a>
          <br />
        </p>

        </LayoutRight3>


        <LeftImagen3>
            <img src={ImagenLeft3} alt=""/>
          </LeftImagen3>

      </ClubSection3>
      {/* ClubSection3_Fin */}

       {/*
       <AboutSection>
        <h1>
          Are you Ready for
          the <span>Kryptopyan</span>?
        </h1>
        <p>
          Kryptopyans is a collection of 10,000 NFTs (non-fungible tokens) based on the Ethereum network,
          which are made up of more than 1000 visual characteristics, of different rarities.
          <br />
          <br />
          Avatars Kryptopyans  are high quality 3D designs, including different Roles,
          like CyberPunks, Astronauts, Warriors, Soccer Players, Cybors and many more.
          <br /> <br />
          Among its Attributes you can find hundreds of Skins, Facial Expressions,
          incredible Clothes, Hats, Lenses, different Worlds and a lot of technology.
          <br />
          The Kryptopyans Metaverse is already under construction
          and experiences will come that will only be available to collectors.
          <br />
          <br />
          Owning a Kryptopyan allows you to participate in Airdrops,
          access exclusive products in the store, attend virtual events,
          and be part of a great community.
          <br />
          <br />
          To know more you can see our <a class="colorRoadmap" href="#roadmap">Roadmap.</a>
          <br />
        </p>
      </AboutSection>
      */}


      {/* Carousel_Inicio */}
      <CarouselSection id="nfts" style={{ backgroundImage: `url(${Background10})`, backgroundRepeat: 'no-repeat', backgroundSize: '1480px', backgroundPosition: 'bottom' , backgroundColor:'#A3B0B4' }}>

        <Carousel/>
        <h1>COLLECTION NFTS</h1>

        </CarouselSection>
      {/* Carousel_Fin */}

      <Separador style={{ backgroundColor:'#57d6fb', padding:'1px' }}></Separador>
      
      {/* ClubSection_Inicio */}
      <ClubSection>
        
         <a
              href="http://discord.com/"
              target="_blank"
              rel="noreferrer"
            >
              {window.screen.width < 565 ? 
        <SecondaryBtn text="JOIN DISCORD" />
         : null}
            </a>

        <LeftImagen>
            <img src={ImagenLeft} alt=""/>
          </LeftImagen>
        <LayoutRight>
      
          <h1>JOIN THE<span> CLUB</span>
             </h1>
          <p>
            Kryptopyans have their Club on Discord, where everyone is welcome to
            participate, collaborate, and contribute ideas about the project and its development.
            <br /> <br />
            Official announcements about Minteo dates will be made within the Club,
            Airdrops, and other events.
            <br /> <br />
            We also have a private Club,
            exclusive for collectors, where they can interact
            directly with the other owners, vote on the proposals of the
            community and be the first to know about the launches. 
            <br /> <br /> 
            Together this transformation and road to success.
            <br /> <br /> <br />
            Join now!
          </p>

          <a
              href="http://discord.com/"
              target="_blank"
              rel="noreferrer"
            >
              {window.screen.width > 565 ? (
            <SecondaryBtn text="JOIN DISCORD" />
          ) : null}
            </a>

        </LayoutRight>
      </ClubSection>
      {/* ClubSection_Fin */}

      <Separador style={{ backgroundColor:'#2C6540', padding:'1px' }}></Separador>
 
      {/* Team_Inicio */}
      <TeamArtistSection id="team">
      
        <h1>THE KRYPTOPYAN <span> TEAM</span></h1>
        <div>
          {" "}
          <p>
            {" "}
            A Squad committed and dedicated to creating great experiences for the community.
          </p>
        </div>

        <ArtistContainer style={{ backgroundImage: `url(${Background12})`, backgroundRepeat: 'no-repeat', backgroundSize: '1480px', backgroundColor: '#000', backgroundPosition: 'top', padding: '70px 0px 70px 0px'}}>
          <ArtistCard
            name="The Squad"
            text="Follow us in Networks:"
            twitter="KryptopyansClub"
          />  
          {/*
           <ArtistCard
            name="Maria"
            text="Seguinos en Redes:"
            twitter="KryptopyansClub"
          />   
           <ArtistCard
            name="Steve"
            text="Seguinos en Redes:"
            twitter="KryptopyansClub"
          />  
          */}       
        </ArtistContainer>

      </TeamArtistSection>
      {/* Team_Fin */}

      <Separador style={{ backgroundColor:'#2C6540', padding:'1px' }}></Separador>

      <Roadmap id="roadmap"/>

      <FaqSection id="faq">
            
            <h1>FAQ</h1>

            <QuestionsContainer>
              <Question>
                <div onClick={() => setViewAnswer1(!viewAnswer1)}>
                  <h2>
                  What is an NFT?
                  </h2>
                  <h2> + </h2>
                </div>
                {viewAnswer1 === true && (
                  <Answer>
                    <p>An NFT (non-fungible token) is a work of art that lives on the blockchain. Owning an NFT is like owning a painting, but instead of physically owning it, you own it virtually, on the blockchain.
                      Kryptopyan is a collection of 10,000 unique NFTs that will live on the Ethereum blockchain.
                    </p>
                  </Answer>
                )}
              </Question>
              <Question>
                <div onClick={() => setViewAnswer2(!viewAnswer2)}>
                  <h2>
                  What is Minting?
                  </h2>
                  <h2> + </h2>
                </div>
                {viewAnswer2 === true && (
                  <Answer>
                    <p>
                      Mining a Kryptopyan means that you are purchasing digital art that becomes part of the Ethereum blockchain. 
                      Your digital artwork is represented as an NFT, 
                      so it can be traded in the marketplace and digitally tracked as it is resold or collected again in the future.
                      </p>
                    </Answer>
                )}
              </Question>
              <Question>
                <div onClick={() => setViewAnswer3(!viewAnswer3)}>
                  <h2>
                  Where can I see my NFT after minting?
                    </h2>
                  <h2> + </h2>
                </div>
                {viewAnswer3 === true && (
                  <Answer>
                    <p>
                      Once minted, simply log in to your OpenSea account to view your NFTs.
                    </p>
                    </Answer>
                )}
              </Question>
              <Question>
                <div onClick={() => setViewAnswer4(!viewAnswer4)}>
                  <h2>
                  How can I join the presale list?
                    </h2>
                  <h2> + </h2>
                </div>
                {viewAnswer4 === true && (
                  <Answer>
                    <p>Join us on Discord, we explain it too!</p>
                    </Answer>
                )}
              </Question>
              <Question>
                <div onClick={() => setViewAnswer5(!viewAnswer5)}>
                  <h2>
                  How to connect my wallet?
                    </h2>
                  <h2> + </h2>
                </div>
                {viewAnswer5 === true && (
                  <Answer>
                    <p>
                      After creating your wallet, you will only need to click on the button in the header of this page 'Connect wallet' and make sure you are on the Ethereum network.
                    </p>
                    </Answer>
                )}
              </Question>
              <Question>
                <div onClick={() => setViewAnswer6(!viewAnswer6)}>
                  <h2>
                  What is Open Sea?
                    </h2>
                  <h2> + </h2>
                </div>
                {viewAnswer6 === true && (
                  <Answer>
                    <p>
                      OpenSea is an innovative decentralized marketplace for buying and selling NFTs.
                    </p>
                    </Answer>
                )}
              </Question>
            </QuestionsContainer>
          </FaqSection>

          <SmartContractSection>
            <p>
              {" "}
              VERIFIED SMART CONTRACT ADDRESS:
              <br />
              <a
                href="https://etherscan.io/address/0x0e929f49ed7bda57ec597d979e9d8f0ec100cffa#code"
                target={"_blank"}
                rel="noreferrer"
              >
                0x0e929f49ed7bda57ec597d979e9d8f0ec100cffa{" "}
              </a>
            </p>
          </SmartContractSection>


      <Footer />
    </Main>
  );
};

export default Home;


//Styles Estilos CSS

const Main = styled.div`
  font-family: Nasalization;
`;
 
const Header = styled.div`
  background-color: #101010;
  display: flex;
  color: white;
  padding: 3rem 2rem;
  align-items: center;
  height: calc(100vh - 4rem - 97px);
  @media ${device.tabletMS} {
    padding: 3rem 0rem 0rem;
    height: calc(100vh - 4rem);
  }
`; 

const SubHeader = styled.div`
  display: flex;
  color: white;
  padding: 3rem 2rem;
  align-items: center;
  height: calc(100vh - 4rem - 97px);
  @media ${device.tabletMS} {
    padding: 3rem 2rem 1rem;
    height: calc(100vh - 4rem);
  }
`; 

const LeftLayout = styled.div`
  margin-left: 4rem;
  h1 {
    text-shadow: 0px 0px 30px #76F016; 
    color: #effff6;
    font-size: 68px;
    margin-top: 0;
  }
  p {
    margin-bottom: 2rem;
  }
  @media ${device.tabletMS} {
    margin: 0;
    h1 {
      font-size: 40px;
    }
  }
  @media ${device.tabletS} {
    h1 {
      font-size: 30px;
    }
    p {
      font-size: 14px;
    }
  }
  @media ${device.mobileL} {
    width:100%;
    h1 {
      font-size: 50px;
    }
    p {
      font-size: 16px;
    }
  }
  @media ${device.mobileM} {
    width:100%;
    h1 {
      font-size: 40px;
    }
    p {
      font-size: 16px;
    }
  }
`;

const MatrixBackground = styled.div`
  img {
    position: relative;
    right: 200px;
    width: 1800px;
    height: 650px;
    top: 0px;
    opacity: 0.8;
  }
  @media ${device.laptopXL} {
    img {
      position: relative;
      right: 200px;
      width: 1640px;
      height: 650px;
      top: 0px;
      opacity: 0.8;
    }
    }
    @media ${device.laptop} {
      img {
        position: relative;
        right: 200px;
        width: 1640px;
        height: 560px;
        top: 0px;
        opacity: 0.8;
      }
      }
      @media ${device.tablet} {
        display: none
        }
`;

const PortadaContainer = styled.div`
@media ${device.tablet} {
  display: none;
}
`;

const PortadaImagen = styled.div`
margin-bottom: -100px;
@media ${device.laptop} {
  img {
    margin-bottom: -120px;
    width: 500px;
    height: 500px;
  }
}
`;

const MatrixBackgroundMobile = styled.div`
display: none;
  @media ${device.tabletS} {
    display: flex;
    width: 100%;
    height: auto;
    margin: 0px;
    img {
      width: 100%;
      height: auto;
      opacity: 0.3;
    }
  }
`;

const PortadaContainerResponsive = styled.div`
  img {
    width: 210px;
    height: 210px;
    position: relative;
    top: 80px;
    right: 50px;
  }
  display: none;
  @media ${device.tabletMS} {
    display: none;
  }
`;

const scroll = keyframes`
0% {
    transform: translateX(0);
  }
  100%{
    transform: translateX(calc((-858.033px + -4rem) * 2));
  }
`;

const Banner = styled.div`
  padding: 30px 1rem;
  background-color: black;
  width: calc(100% - 2rem);
  height: auto;
  overflow: hidden;
  position: relative;
  display: grid;
  place-items: center;
`;
const Items = styled.div`
  display: flex;
  width: calc((858.033px + 4rem) * 4);
  animation: ${scroll} 18s linear infinite;
`;
const Item = styled.div`
color: white;
  height: auto;
  width: max-content;
  display: flex;
  align-items: center;
  margin: 0 2rem;
  border-radius: 20px;
  font-size: 26px;
  font-family: Ethnocentric;
  transition:all 300ms; 
  cursor:pointer;
  :hover{
    text-shadow: 0px 0px 40px #76F016;
    color: #76F016;
  }
  h1{
    margin:0;
    width:max-content;
  }
`;

const StorySection = styled.div`
background-color: #161616;
  color: white;
  padding: 2rem 20rem;
  text-align: center;
  min-height: calc(100vh - 4rem);
  h1 {
    font-size: 50px;
    span {
      color: #76F016;
    }
  }
  p {
    text-align: justify;
    text-align-last: left;
    font-size: 18px;
  }
  @media ${device.tablet} {
    padding: 2rem;
    h1 {
      font-size: 40px;
    }
  }
  @media ${device.mobileM} {
    padding: 2rem;
    h1 {
      font-size: 30px;
    }
  }
`;

const VideoSection = styled.div`
  background-color: #73EC43;
  color: white;
  text-align: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  font-family: Ethnocentric;
`;

const Separador = styled.div`
  `;

const AboutSection = styled.div`
  background-color: #101010;
  color: white;
  padding: 2rem 20rem;
  text-align: center;
  min-height: calc(100vh - 4rem);
  h1 {
    font-size: 50px;
    span {
      color: #76F016;
    }
  }
  p {
    text-align: justify;
    text-align-last: left;
    font-size: 18px;
  }
  @media ${device.tablet} {
    padding: 2rem;
    h1 {
      font-size: 40px;
    }
  }
  @media ${device.mobileM} {
    padding: 2rem;
    h1 {
      font-size: 30px;
    }
  }
`;

const TeamArtistSection = styled.div`

  background-color: #000;
  text-align: center;
  h1 {
    font-size: 54px;
    margin: 0px;
    padding: 50px 0px 0px 0px;
    color: #fff;
      span {
        color: #73EC43;
      }
  }
  h2 {
    color: #fff;
  }
  div p {
    font-size: 18px;
    color: #fff;
    padding-bottom: 15px;
  }
  a {
    color: #fff;
  }
  @media ${device.tabletMS} {
    h1 {
      font-size: 40px;
      color: #fff;
    }
  }
`;
const ArtistContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px;
  margin-bottom: 0rem;
  justify-content: space-around;
`;

const CarouselSection = styled.div`
  padding: 200px 0rem 300px 0rem;
  heigth: 300px;
  text-align: center;
  h1 {
    position: relative;
    top: 30px;
    text-shadow: 0px 0px 30px #fff; 
    color: #effff6;
    font-size: 68px;
    margin: 0px;
  }
  @media ${device.tabletS} {
    h1 {
      font-size: 38px;
    }
  }
  }
`;

const ClubSection = styled.div`
  display: flex;
  background-color: #010101;
  color: white;
  padding: 2rem 2rem 0rem 2rem;
  align-items: center;
  justify-content: space-around;
  min-height: calc(100vh - 4rem);
  @media ${device.tabletS} {
    flex-direction: column-reverse;
  }
  h1 {
    span {
      color: #57d6fb;
    }
  }
`;

const LayoutRight = styled.div`
  max-width: 600px;
  h1 {
    font-size: 46px;
  }
  div {
    margin-top: 4rem;
  }
  @media ${device.mobileL} {
    h1 {
      font-size: 40px;
    }
  }
`;

const FaqSection = styled.div`
padding: 2rem 2rem 4rem 2rem;
background-color: #010101;
text-align: center;
color: white;
position: relative;
z-index: 1;
h1 {
font-size: 48px;
font-family: Ethnocentric;
}
`;

const Question = styled.div`
text-align: left;
align-items: center;
display: flex;
flex-direction: column;
justify-content: space-between;
width: calc(100% - 4rem);
max-width: 600px;
background-color: #151515;
margin: 1rem 0;
padding: 0.75rem 2rem;
border-radius: 0px 20px 0px 20px;
border: 2px solid #76F016;
font-size: 16px;
h2 {
  margin: 0;
  -webkit-text-stroke: 1px white;
  }
  div {
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  @media ${device.tabletMS} {
    width: 500px;
  }
  @media ${device.tabletS} {
    width: 400px;
  }
  @media ${device.mobileL} {
    width: 300px;
    font-size: 16px;
    svg {
      width: 20px;
    }
    h2 {
      font-size: 24px;
    }
  }
  @media ${device.mobileM} {
    width: 250px;
  }
`;

const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Answer = styled.div`
  text-align: justify;
  color: #76F016; 
  @media ${device.mobileL} {
    font-size: 18px;
  }
`;

const LeftImagen = styled.div`
  margin-top: 20px;
  margin-bottom: 0px;
  @media ${device.tablet} {
    display: none;
  }
`;

const SmartContractSection = styled.div`
  padding: 2rem;
  height: 100px;
  background-color: #010101;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    text-align: center;
    font-size: 30px;
    color: white;
    a {
      color: #81b1fc;
      font-size: 25px;
      color: #73EC43;
    }
  }
  @media ${device.tabletMS} {
    p a {
      font-size: 16px;
    }
    @media ${device.tabletS} {
      p a {
        font-size: 14px;
      }
    }
    @media ${device.mobileL} {
      p a {
        font-size: 12px;
      }
    }
    @media ${device.mobileM} {
      p a {
        font-size: 10px;
      }
    }
  }
  `;

  const ClubSection2 = styled.div`
  display: flex;
  background-color: #010101;
  color: white;
  padding: 2rem 2rem 0rem 2rem;
  align-items: center;
  justify-content: space-around;
  min-height: calc(100vh - 4rem);
  @media ${device.tabletS} {
    flex-direction: column-reverse;
  }
  h1 {
    span {
      color: #73EC43;
    }
  }
`;

  const ClubSection3 = styled.div`
  display: flex;
  background-color: #010101;
  color: white;
  padding: 2rem 2rem 0rem 2rem;
  align-items: center;
  justify-content: space-around;
  min-height: calc(100vh - 4rem);
  @media ${device.tabletS} {
    flex-direction: column-reverse;
  }
  h1 {
    span {
      color: #73EC43;
    }
  }
`;

const LayoutRight3 = styled.div`
  max-width: 600px;
  h1 {
    font-size: 46px;
  }
  div {
    margin-top: 4rem;
  }
  @media ${device.mobileL} {
    h1 {
      font-size: 40px;
    }
  }
`;

const LeftImagen3 = styled.div`
  margin-top: 20px;
  margin-bottom: 0px;
  @media ${device.tablet} {
    display: none;
  }
`;