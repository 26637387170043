import styled from "styled-components";
import logo from "../assets/eab09b8c71b3c0a5177d1e800040e411.png";
import logoverde from "../assets/kryptopyans_verde.png";
import iconOpensea from "../assets/opensea.png";
import iconTwitter from "../assets/86d207fad7a53cc1f67cf04e20b6da90.png";
import iconDiscord from "../assets/2cf665900344d7a1dc152c3ee8940692.png";
import iconInstagram from "../assets/3773eb5fa0fb261da4d52873748d08f0.png";
import AbstractIcon from "../assets/BurgerIcon/AbstractIcon.svg";
import CloseIcon1 from "../assets/BurgerIcon/CloseIcon.svg";
import CloseIcon2 from "../assets/BurgerIcon/CloseIcon.svg";

import Background10 from "../assets/matrixmobile.png";

import { device } from "../device";
import { useState } from "react";
const Navbar = () => {
  const [isClicked, setIsClicked] = useState(false);

  return (
    <Main style={{ backgroundImage: `url(${Background10})`, backgroundRepeat: 'repeat', backgroundPosition: 'center' }}>
      <Logo>
        <img src={logo} alt="" />
      </Logo>
      <Container isClicked={isClicked}>
        <Item>
            <a href="#about">About</a>
          </Item>
          <Item>
            <a href="#nfts">NFTs</a>
          </Item>
          <Item>
            <a href="#team">Team</a>
          </Item>
          <Item>
            <a href="#roadmap">Roadmap</a>
          </Item>
          <Item>
            <a href="#faq">Faq</a>
          </Item>


        <IconsResponsive>
        <OpenseaIcon>
              <a
                href="https://opensea.io/collection/kryptopyans"
                target="_blank"
                rel="noreferrer"
              >
                <img src={iconOpensea} alt="Opensea" />
              </a>
            </OpenseaIcon>
            <TwitterIcon>
              <a
                href="https://twitter.com/kryptopyansclub"
                target="_blank"
                rel="noreferrer"
              >
                <img src={iconTwitter} alt="Twitter" />
              </a>
            </TwitterIcon>
            <DiscordIcon>
              <a
                  href="https://discord.com/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img src={iconDiscord} alt="Discord" />
                </a>
            </DiscordIcon>
            <InstagramIcon>
              <a
                href="https://instagram.com/kryptopyansclub"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={iconInstagram} alt="Instagram" />
              </a>
            </InstagramIcon>
        </IconsResponsive>

        <BottomSide onClick={() => setIsClicked(!isClicked)}>
          <img src={CloseIcon2} alt="Instagram" />
        </BottomSide>

      </Container>

      <RightContainer>
        
      <IconsMain display={window.screen.width}>
            <OpenseaIcon>
              <a
                href="https://opensea.io/collection/kryptopyans"
                target="_blank"
                rel="noreferrer"
              >
                <img src={iconOpensea} alt="Opensea" />
              </a>
            </OpenseaIcon>
            <TwitterIcon>
              <a
                href="https://twitter.com/kryptopyansclub"
                target="_blank"
                rel="noreferrer"
              >
                <img src={iconTwitter} alt="Twitter" />
              </a>
            </TwitterIcon>
            <DiscordIcon>
              <a
                  href="https://discord.com/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img src={iconDiscord} alt="Discord" />
                </a>
            </DiscordIcon>
            <InstagramIcon>
              <a
                href="https://instagram.com/kryptopyansclub"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={iconInstagram} alt="Instagram" />
              </a>
            </InstagramIcon>
          </IconsMain>

          <a
              href="http://minting.kryptopyanworld.com/"
              target="_blank"
              rel="noreferrer"
            >
              <ConnectBtn>
          <p>Connect Wallet</p>
        </ConnectBtn>
            </a>
            
            
      </RightContainer>

      <HamburguerIcon isClicked={isClicked}>
        <img src={AbstractIcon} onClick={() => setIsClicked(!isClicked)} size={50} />
        
      </HamburguerIcon>
      <CloseIcon isClicked={isClicked}>
        <img src={CloseIcon1} onClick={() => setIsClicked(!isClicked)} size={25} />
      </CloseIcon>
    </Main>
  );
};



export default Navbar;



const Main = styled.div`
  position: relative;
  height: 65px;
  display: flex;
  background-color: #202526;
  width: calc(100vw - 4rem);
  color: white;
  padding: 1rem 2rem;
  align-items: center;
  justify-content: space-between;
  @media ${device.tabletMS} {
    position: fixed;
    z-index: 2;
  }
  @media ${device.mobileL} {
    width: calc(100vw - 2rem);
    padding: 1rem 1rem;
  }
`;
const Logo = styled.div`
  width: 60px;
  img {
    width: 100%;
  }
  @media ${device.mobileL} {
    width: 45px;
  }
`;
const Container = styled.div.attrs((props) => ({
  top: props.isClicked === true ? "97px" : "-200%",
}))`
  display: flex;
  transition: all 300ms;
  @media ${device.tabletMS} {
    background-color: #0f1212;
    width: 100%;
    flex-direction: column;
    position: fixed;
    height: calc(100vh - 97px);
    z-index: -1;
    top: ${(props) => props.top};
    right: 0;
    justify-content: space-around;
    div {
      display: flex;
      align-item: center;
      margin-left: 2rem;
    }
  }
`;
const Item = styled.div`
  margin: 0 12px;
  transition: all 300ms;
  cursor: pointer;
  font-size: 20px;
  :hover {
    a {
      color: #76F016 !important;
    }
    transform: scale(1.1);
  }
}
@media ${device.tabletMS} {
  margin-left: 2rem;
  }
`;
/*const Item = styled.div`
  margin: 0 12px;
  cursor: pointer;
  font-size: 18px;
  :hover {
    color: #76F016;
  }
  @media ${device.tabletMS} {
    margin-left: 2rem;
  }
`;*/
const ConnectBtn = styled.div`
  border: 2px solid #76F016;
  border-radius: 10px 30px 10px 30px;
  padding: 0.5em 2em;
  margin-left: 2rem;
  transition: all 300ms;
  cursor: pointer;
  font-size: 24px;
  text-align: center;
  p {
    margin: 0;
  }
  :hover {
    color: black;
    background-color: #e1f9ec;
    box-shadow: 0px 0px 20px 0px #23da79;
  }
  @media ${device.tabletMS} {
    margin: 0;
  }
  @media ${device.tablet} {
    margin-left: 80px;
    font-size: 18px;
    padding: 0.5em 1em !important;
  }
`;
const RightContainer = styled.div`
  display: flex;
  @media ${device.tabletMS} {
    align-items: center;
    justify-content: space-between;
  }
`;
const icon = styled.div`
  display: flex;
  align-items: center;
  width: 35px;
  margin: 0 4px;
  transition: all 300ms;
  cursor: pointer;
  img {
    width: 100%;
  }
  :hover {
    color: #fff;
    transform: translateY(-3px);
  }
`;

const OpenseaIcon = styled(icon)`
  margin: 0 5px !important;
  margin-left: 0 !important;
  width: 32px;
`;

const TwitterIcon = styled(icon)`
  margin: 0 5px !important;
  margin-left: 5px !important;
`;
const DiscordIcon = styled(icon)`
  margin: 0 5px !important;
  width: 35px;
`;
const InstagramIcon = styled(icon)`
  margin: 0 5px !important;
  width: 28px;
`;

const HamburguerIcon = styled.div.attrs((props) => ({
  display: props.isClicked ? "none" : "flex",
}))`
  display: none;
  transition: all 300ms;
  cursor: pointer;
  border-radius: 4px;
  align-items: center;
  padding: 5px;
  :hover {
    background-color: #0f1212;
  }
  @media ${device.tabletMS} {
    display: ${(props) => props.display};
  }
`;

const CloseIcon = styled.div.attrs((props) => ({
  display: props.isClicked ? "flex" : "none",
}))`
  display: none;
  transition: all 300ms;
  cursor: pointer;
  border-radius: 4px;
  align-items: center;
  padding: 5px;
  :hover {
    background-color: #0f1212;
  }
  @media ${device.tabletMS} {
    display: ${(props) => props.display};
  }
`;

const BottomSide = styled.div`
  display: none;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
  background-color: #181c1c;
  padding: 1rem 0;
  margin-bottom: -1.5rem !important;
  cursor: pointer;
  svg {
    transition: all 300ms;
  }
  :hover {
    svg {
      color: #23da79;
    }
  }
  @media ${device.tabletMS} {
    display: flex;
  }
`;

const IconsMain = styled.div`
  display: flex;
  @media ${device.tablet} {
    display: none;
  }
`;
const IconsResponsive = styled.div`
  display: none;
  @media ${device.tablet} {
    display: none;
  }
`;

