import styled from "styled-components";
import { device } from "../../device";
const SecondaryBtn = ({ text, style }) => {
  return (
    <Container style={style && style}>
      <p>{text}</p>
    </Container>
  );
};

export default SecondaryBtn;

const Container = styled.div.attrs((props) => ({
  fontSize: props.style && props.style.fontSize ? props.style.fontSize : "24px",
}))`
width:max-content;
padding:1rem 2rem;
border: 2px solid #57d6fb;
border-radius 24px;
cursor:pointer;
transition:all 300ms;
p {
    margin:0 !important;
}
:hover{
  color: black;
  background-color: #d8f9ff;
  box-shadow: 0px 0px 30px 0px #16daf0;
}
@media ${device.laptop} {
  margin:1rem 0rem 3rem 0rem;
} 

@media ${device.tabletS} {
  font-size: 18px !important;
  margin:1rem 0rem 3rem 0rem;
} 
`;
