const sizes = {
    mobileS: "320px",
    mobileM: "375px",
    mobileL: "475px",
    tabletS: "565px",
    tabletMS: "715px",
    tabletM: "800px",
    tablet: "960px",
    laptop: "1024px",
    laptopL: "1440px",
    laptopXL: "1479px",
    desktop: "2560px",
  };
  
  export const device = {
    mobileS: `(max-width: ${sizes.mobileS})`,
    mobileM: `(max-width: ${sizes.mobileM})`,
    mobileL: `(max-width: ${sizes.mobileL})`,
    tabletS: `(max-width: ${sizes.tabletS})`,
    tabletMS: `(max-width: ${sizes.tabletMS})`,
    tabletM: `(max-width: ${sizes.tabletM})`,
    tablet: `(max-width: ${sizes.tablet})`,
    laptop: `(max-width: ${sizes.laptop})`,
    laptopL: `(max-width: ${sizes.laptopL})`,
    laptopXL: `(max-width: ${sizes.laptopXL})`,
    desktop: `(max-width: ${sizes.desktop})`,
  };