const Roadmap = () => {
  return (
    <>
      <div
        class="heading-wrapper-center inner-padding-medium our-roadmap"
        id="roadmap"
      >
        {" "}
        <h1> RoadMap</h1>{" "}
      </div>
      <div class="section-timeline">
        <div class="container-2">
          <div class="timeline_wrapper">
            <div class="timeline_progress-bar mobile-bar"></div>
            <div class="timeline_progress"></div>
            {window.screen.width > 715 ? (
              <>
                <div class="timeline_item item-mobile">
                  <div class="timeline_left">
                    <div class="timeline_date-text">
                      <strong>
                      1st Mission:
                        <div>10%</div>
                        <br />
                      </strong>
                    </div>
                  </div>
                  <div class="timeline_centre">
                    <div class="timeline_circle"></div>
                  </div>
                  <div class="timeline_right">
                    <div>
                      <p>
                      Kryptopyan's Journey to Planet Earth from Alpha Centauri B. 
                      Launch of Social Networks and NFTs.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="timeline_item item-mobile">
                  <div class="timeline_left">

                  <div class="timeline_date-text">
                      <strong>
                      Whitelist:
                        <div>20%</div>
                      </strong>
                    </div>
                    
                  </div>
                  <div class="timeline_centre">
                    <div class="timeline_circle"></div>
                  </div>
                  <div class="timeline_right">
                    <div>
                      <p>
                        The first 3,000 Kryptopyans arrive on earth.
                         A WhiteList is made to access the first 3000 undisclosed NFTs.
                        <br/><p id="precio"> Price: 0.20 eth.</p>
                        After the mining is done, an Airdrop will be done where 100 owners will receive an exclusive prize.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="timeline_item item-mobile">
                  <div class="timeline_left">

                  <div class="timeline_date-text">
                      <strong>
                      2nd Mission:
                        <div>30%</div>
                      </strong>
                    </div>

                  </div>
                  <div class="timeline_centre">
                    <div class="timeline_circle"></div>
                  </div>
                  <div class="timeline_right">
                    <div>
                      <p>
                      The other 7,000 Kryptopyans arrive on earth.
                      <br/><p id="precio"> Price: 0.30 eth.</p>
                      Draw of 20 motor vehicles in 0km in the real world. exclusive for holders.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="timeline_item item-mobile">
                  <div class="timeline_left">

                  <div class="timeline_date-text">
                      <strong>
                      Kryptopyan Store:
                        <div>40%</div>
                      </strong>
                    </div>

                  </div>
                  <div class="timeline_centre">
                    <div class="timeline_circle"></div>
                  </div>
                  <div class="timeline_right">
                    <div>
                      <p>
                      The online store is enabled, with garments, accessories and other merchandising.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="timeline_item item-mobile">
                  <div class="timeline_left">
                    
                  <div class="timeline_date-text">
                      <strong>
                      Kryptopyan Supreme:
                        <div>50%</div>
                      </strong>
                    </div>

                  </div>
                  <div class="timeline_centre">
                    <div class="timeline_circle"></div>
                  </div>
                  <div class="timeline_right">
                    <div>
                      <p>
                      The Kryptopyans take a trip to their home exoplanet and
                         They return with store discounts and many more rewards.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="timeline_item item-mobile">
                  <div class="timeline_left">
                    <div class="timeline_date-text">
                      <strong>
                      VR filter:
                        <div>60%</div>
                        <br />
                      </strong>
                    </div>
                  </div>
                  <div class="timeline_centre">
                    <div class="timeline_circle"></div>
                  </div>
                  <div class="timeline_right">
                    <div>
                      <p>
                      The Digital Identity Filter is activated for virtual events and group video calls.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="timeline_item item-mobile">
                  <div class="timeline_left">

                  <div class="timeline_date-text">
                      <strong>
                      Krycoin:
                        <div>70%</div>
                      </strong>
                    </div>
                    
                  </div>
                  <div class="timeline_centre">
                    <div class="timeline_circle"></div>
                  </div>
                  <div class="timeline_right">
                    <div>
                      <p>
                      Launch of the Krycoin Token, with a Strong economy of great utility in the metaverse.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="timeline_item item-mobile">
                  <div class="timeline_left">

                  <div class="timeline_date-text">
                      <strong>
                      Kryptopyan City:
                        <div>80%</div>
                      </strong>
                    </div>

                  </div>
                  <div class="timeline_centre">
                    <div class="timeline_circle"></div>
                  </div>
                  <div class="timeline_right">
                    <div>
                      <p>
                      Kryptopyan City launch and development of the metaverse.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="timeline_item item-mobile">
                  <div class="timeline_left">

                  <div class="timeline_date-text">
                      <strong>
                      Avatars 3D:
                        <div>90%</div>
                      </strong>
                    </div>

                  </div>
                  <div class="timeline_centre">
                    <div class="timeline_circle"></div>
                  </div>
                  <div class="timeline_right">
                    <div>
                      <p>
                      The delivery of the Kryptopyans Avatars, the collection of 3D models, is made to enter Kryptopyan City.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="timeline_item item-mobile">
                  <div class="timeline_left">
                    
                  <div class="timeline_date-text">
                      <strong>
                      The Metaverse:
                        <div>100%</div>
                      </strong>
                    </div>

                  </div>
                  <div class="timeline_centre">
                    <div class="timeline_circle"></div>
                  </div>
                  <div class="timeline_right">
                    <div>
                      <p>
                      Lands are enabled and announced
                       Launching amazing activities in the metaverse.
                      </p>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div class="timeline_item item-mobile">
                  <div class="timeline_left">
                    <div class="timeline_circle"></div>
                  </div>
                  <div class="timeline_centre"></div>
                  <div class="timeline_right">
                    <div class="timeline_date-text">
                      <strong>
                      1st Mission:
                        <div>10%</div>
                        <br />
                      </strong>
                    </div>
                    <div>
                      <p>
                      Kryptopyan's Journey to Planet Earth from Alpha Centauri B.
                       Launch of Social Networks and NFTs.
                      </p>
                    </div>
                  </div>
                </div>



                <div class="timeline_item item-mobile">
                  <div class="timeline_left">
                    <div class="timeline_circle"></div>
                  </div>
                  <div class="timeline_centre"></div>
                  <div class="timeline_right">
                    <div class="timeline_date-text">
                      <strong>
                      Whitelist:
                      <div>20%</div>
                      </strong>
                    </div>
                    <div>
                      <p>
                      The first 3,000 Kryptopyans arrive on earth.
                         A WhiteList is made to access the first 3000 undisclosed NFTs.
                        <br/><p id="precio"> Price: 0.20 eth.</p>
                        After the mining is done, an Airdrop will be done where 100 owners will receive an exclusive prize.
                      </p>
                    </div>
                  </div>
                </div>



                <div class="timeline_item item-mobile">
                  <div class="timeline_left">
                    <div class="timeline_circle"></div>
                  </div>
                  <div class="timeline_centre"></div>
                  <div class="timeline_right">
                    <div class="timeline_date-text">
                      <strong>
                      2nd Mission:
                        <div>30%</div>
                      </strong>
                    </div>
                    <div>
                      <p>
                      The other 7,000 Kryptopyans arrive on earth.
                      <br/><p id="precio"> Price: 0.30 eth.</p>
                      Draw of 20 motor vehicles in 0km in the real world. exclusive for holders.
                      </p>
                    </div>
                  </div>
                </div>



                <div class="timeline_item item-mobile">
                  <div class="timeline_left">
                    <div class="timeline_circle"></div>
                  </div>
                  <div class="timeline_centre"></div>
                  <div class="timeline_right">
                    <div class="timeline_date-text">
                    <strong>
                      Kryptopyan Store:
                      <div>40%</div>
                      </strong>
                    </div>
                    <div>
                      <p>
                      The online store is enabled, with garments, accessories and other merchandising.
                      </p>
                    </div>
                  </div>
                </div>



                <div class="timeline_item item-mobile">
                  <div class="timeline_left">
                    <div class="timeline_circle"></div>
                  </div>
                  <div class="timeline_centre"></div>
                  <div class="timeline_right">
                    <div class="timeline_date-text">
                    <strong>
                      Kryptopyan Supreme:
                      <div>50%</div>
                      </strong>
                    </div>
                    <div>
                      <p>
                      The Kryptopyans take a trip to their home exoplanet and
                         They return with store discounts and many more rewards.
                      </p>
                    </div>
                  </div>
                </div>


                <div class="timeline_item item-mobile">
                  <div class="timeline_left">
                    <div class="timeline_circle"></div>
                  </div>
                  <div class="timeline_centre"></div>
                  <div class="timeline_right">
                    <div class="timeline_date-text">
                      <strong>
                      VR filter:
                        <div>60%</div>
                        <br />
                      </strong>
                    </div>
                    <div>
                      <p>
                      The Digital Identity Filter is activated for virtual events and group video calls.
                      </p>
                    </div>
                  </div>
                </div>



                <div class="timeline_item item-mobile">
                  <div class="timeline_left">
                    <div class="timeline_circle"></div>
                  </div>
                  <div class="timeline_centre"></div>
                  <div class="timeline_right">
                    <div class="timeline_date-text">
                      <strong>
                      Krycoin:
                      <div>70%</div>
                      </strong>
                    </div>
                    <div>
                      <p>
                      Launch of the Krycoin Token, with a Strong economy of great utility in the metaverse.
                      </p>
                    </div>
                  </div>
                </div>



                <div class="timeline_item item-mobile">
                  <div class="timeline_left">
                    <div class="timeline_circle"></div>
                  </div>
                  <div class="timeline_centre"></div>
                  <div class="timeline_right">
                    <div class="timeline_date-text">
                      <strong>
                      Kryptopyan City:
                        <div>80%</div>
                      </strong>
                    </div>
                    <div>
                      <p>
                      Kryptopyan City launch and development of the metaverse.
                      </p>
                    </div>
                  </div>
                </div>



                <div class="timeline_item item-mobile">
                  <div class="timeline_left">
                    <div class="timeline_circle"></div>
                  </div>
                  <div class="timeline_centre"></div>
                  <div class="timeline_right">
                    <div class="timeline_date-text">
                    <strong>
                      Avatars 3D:
                      <div>90%</div>
                      </strong>
                    </div>
                    <div>
                      <p>
                      The delivery of the Kryptopyans Avatars, the collection of 3D models, is made to enter Kryptopyan City.
                      </p>
                    </div>
                  </div>
                </div>



                <div class="timeline_item item-mobile">
                  <div class="timeline_left">
                    <div class="timeline_circle"></div>
                  </div>
                  <div class="timeline_centre"></div>
                  <div class="timeline_right">
                    <div class="timeline_date-text">
                    <strong>
                    The Metaverse:
                      <div>100%</div>
                      </strong>
                    </div>
                    <div>
                      <p>
                      Lands are enabled and announced
                       Launching amazing activities in the metaverse.
                      </p>
                    </div>
                  </div>
                </div>

              </>
            )}
            <div class="overlay-fade-bottom"></div>
            <div class="overlay-fade-top tablet-01"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Roadmap;
  
