import styled from "styled-components";
import iconOpensea from "../assets/opensea.png";
import iconTwitter from "../assets/86d207fad7a53cc1f67cf04e20b6da90.png";
import iconDiscord from "../assets/2cf665900344d7a1dc152c3ee8940692.png";
import iconInstagram from "../assets/3773eb5fa0fb261da4d52873748d08f0.png";
import logo from "../assets/eab09b8c71b3c0a5177d1e800040e411.png";
import { device } from "../device";

const Footer = () => {
  return (
    <Container>

<IconsMain display={window.screen.width}>
            <OpenseaIcon>
              <a
                href="https://opensea.io/collection/kryptopyans"
                target="_blank"
                rel="noreferrer"
              >
                <img src={iconOpensea} alt="Opensea" />
              </a>
            </OpenseaIcon>
            <TwitterIcon>
              <a
                href="https://twitter.com/kryptopyansclub"
                target="_blank"
                rel="noreferrer"
              >
                <img src={iconTwitter} alt="Twitter" />
              </a>
            </TwitterIcon>
            <DiscordIcon>
              <a
                  href="https://discord.com/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img src={iconDiscord} alt="Discord" />
                </a>
            </DiscordIcon>
            <InstagramIcon>
              <a
                href="https://instagram.com/kryptopyansclub"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={iconInstagram} alt="Instagram" />
              </a>
            </InstagramIcon>
          </IconsMain>


      <Logo>
        <img src={logo} alt="" />
      </Logo>
      <p>2022 Kryptopyan - All Rights Reserved</p>
    </Container>
  );
};

export default Footer;

//display: none; en mobile
const IconsMain = styled.div`
  display: flex;
  @media ${device.tabletMS} {
    
  }
`;

const icon = styled.div`
  display: flex;
  align-items: center;
  width: 35px;
  margin: 0 4px;
  transition: all 300ms;
  cursor: pointer;
  img {
    width: 100%;
  }
  :hover {
    transform: translateY(-3px);
  }
`;

const OpenseaIcon = styled(icon)`
  margin: 0 5px !important;
  margin-left: 0 !important;
  width: 32px;
`;

const TwitterIcon = styled(icon)`
  margin: 0 5px !important;
  margin-left: 5px !important;
`;
const DiscordIcon = styled(icon)`
  margin: 0 5px !important;
  width: 35px;
`;
const InstagramIcon = styled(icon)`
  margin: 0 5px !important;
  width: 28px;
`;







const Container = styled.footer`
  background-color: #000;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color:white;
  p{
      font-size:14px;
      margin-bottom: 0;
  }
`;

const Logo = styled.div`
  width: 90px;
  img {
    width: 100%;
  }
`;

const SocialMedia = styled.div`
margin-right:4px;
  display: flex;
  align-items:center;
  div{
      margin:0 4px;
  }
`;

const image = styled.div`
  img {
    width: 100%;
  }
`;

const ImageTwitter = styled(image)`
width:35px;
`;
const ImageDiscord = styled(image)`
width:35px;
`;
const ImageInstagram = styled(image)`
width:29px;
`;
